import styled from 'styled-components';

const Thumbnail = styled.button`
  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  background-image: url(${p => p.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 5px;

  transition: all .2s ease-in-out;

  &:hover, &:focus {
    margin: -3px;
    box-shadow: 0 5px 10px rgba(0,0,0, .5);
  }
`;

export default Thumbnail;
