import React from 'react';
import styled from 'styled-components';

const Biography = () => {
  return (
    <Wrapper>
      <p>
        <strong>Supervision André Duchesne</strong> est une entreprise innovante
        qui se recommande aussi bien par l’assurance technique et financière
        de ses devis, que par la qualité des services dont elle se porte
        garante, quel que soit le budget ou l’ordre de grandeur du projet
        envisagé. Fier partenaire de <strong>Tentes Fiesta Ltée.</strong>, tous nos
        modèles de tente sont de qualité <em>A1</em> et approuvés par un ingénieur.
      </p>
      <p>
        Fondée en 1993, <strong>Supervision André Duchesne Inc.</strong> œuvre
        dans la location de tentes pour toutes sortes d’évènements autant
        privés que publics. La feuille de route du propriétaire et
        superviseur en chef, monsieur <em>André Duchesne</em>, atteste d’une
        connaissance approfondie et d’une vision d’ensemble des défis de
        planification, de gestion et d’opération des évènements.
      </p>
      <p>
        De fil en aiguille, l’expertise multidisciplinaire développée
        par <strong>Supervision André Duchesne</strong> a contribué à la
        réussite de nombreux congrès, expositions et spectacles dans tous
        les domaines des arts de la scène, ici comme ailleurs en province.
      </p>
      <p>
        Réputée pour la rigueur dans sa planification, l’efficacité de son
        personnel, le professionnalisme, la qualité de son matériel et la
        sécurité de ses montages, <strong>Supervision André Duchesne</strong> est
        l’entreprise à contacter pour tous vos évènements, tel qu’un mariage,
        une fête, un spectacle!
      </p>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  strong {
    font-style: italic;
  }
`;

export default Biography;
