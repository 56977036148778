import React from 'react';
import styled from 'styled-components';

import Logo from "./Logo";
import Biography from "./Biography";
import Photos from "./Photos";
import Contact from "./Contact";
import Footer from "./Footer";

import photosByCategory from "./photos.json";

import { Tabs } from 'react-simple-tabs-component';
import 'react-simple-tabs-component/dist/index.css';

const tabs = photosByCategory.map(({label, photos}) => (
  {
    label,
    Component: () => <Photos photos={photos} />
  }
));

const App = () => {
  return (
    <>
      <Logo />
      <Container>
        <Biography />
        <hr />
        <Tabs tabs={tabs} />
        <hr />
        <Contact />
      </Container>
      <Footer />
    </>
  );
}

const Container = styled.main`
  @media not screen, (max-width: 539px) {
    width: 100%;
    padding: 2em;
  }
  @media screen {
    @media (min-width: 540px) and (max-width: 767px) {
      width: 540px;
      padding: 3em 3.5em;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      width: 720px;
      padding: 4em 5em;
    }
    @media (min-width: 992px) {
      width: 960px;
      padding: 4em 5em;
    }
  }

  margin-left: auto;
  margin-right: auto;

  text-align: justify;

  background-color: white;
  box-shadow: 0 5px 5px rgb(0,0,0),
              0 10px 25px 10px rgba(0,0,0, 0.75);

  hr {
    margin: 3em 0;
    opacity: 50%;
  }
`;

export default App;
