import React from 'react';
import styled from 'styled-components';

const Logo = () => {
  return (
    <Wrapper>
      <img src="/images/logo.png" alt="Logo Supervision André Duchesne" />
    </Wrapper>
  );
}

const Wrapper = styled.header`
  width: 100%;
  height: 100vh;
  padding-top: 15vh;

  img {
    display: block;
    max-width: 90vw;
    margin: 0 auto;
    filter: drop-shadow(0 0 2px rgb(255,255,255))
            drop-shadow(0 10px 5px rgb(255,255,255))
            drop-shadow(0 10px 25px rgba(255,255,255, 0.75));
  }
`;

export default Logo;
