import React from 'react';
import styled from 'styled-components';

const Footer = () => {
  return (
    <Wrapper>
      <small>&copy;2021 Supervision André Duchesne Inc.</small>
    </Wrapper>
  );
}

const Wrapper = styled.footer`
  width: 100%;
  line-height: 2.5em;

  @media screen and (min-width: 540px) {
    margin-top: 5em;
  }

  background-color: black;
  color: #CCC;
  text-align: center;
`;

export default Footer;
