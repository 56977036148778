import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import Lightbox from 'fslightbox-react';

import Thumbnail from "./Thumbnail";

const Photos = ({ photos }) => {
  const [toggler, setToggler] = useState(false);
  const [sourceIndex, setSourceIndex] = useState(0);

  const sources = useMemo(() => (
    photos.map((photo) => photo.imageSrc)
  ), [photos]);

  const handleClick = useCallback((index) => {
    setSourceIndex(index);
    setToggler(state => !state);
  }, []);

  return (
    <>
      <Wrapper>
        {
          photos.map(({ label, thumbSrc }, index) => (
            <Thumbnail
              key={index}
              src={thumbSrc}
              title={label}
              onClick={() => handleClick(index)}
            />
          ))
        }
      </Wrapper>
      <Lightbox
        toggler={toggler}
        sources={sources}
        sourceIndex={sourceIndex}
      />
    </>
  );
}

const Wrapper = styled.section`
  display: grid;

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  gap: 20px;
  place-items: stretch;
`;

export default Photos;
