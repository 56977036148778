import React from 'react';
import styled from 'styled-components';

const Contact = () => {
  return (
    <Wrapper>
      <h1>Contactez-nous</h1>
      <div itemScope itemType="https://schema.org/LocalBusiness">
        <h3 itemProp="name">Supervision André Duchesne</h3>
        <address itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
          <span itemProp="streetAddress">1237-B rue des Artisans</span>
          <span itemProp="addressLocality">Québec</span>,&nbsp;
          <span itemProp="addressRegion">QC</span>,&nbsp;
          <span itemProp="postalCode">G1N 4T7</span>
        </address>
        <span className="telephone" itemProp="telephone">
          <a href="tel:+1-418-692-3134">(418) 692-3134</a>
        </span>
        <span className="email" itemProp="email">
          <a href="mailto:info@supervisionad.com">info@supervisionad.com</a>
        </span>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  h3 {
    color: var(--logo-blue);
    margin: .5em 0;
  }

  span {
    display: block;
    margin: .5em 0;
  }
  span.telephone:before {
    content: "Tel.: ";
  }

  address span {
    margin: 0;

    &:not(:first-child) {
      display: inline;
    }
  }

  a {
    color: inherit;
    text-decoration: none;

    &[href^="mailto:"] {
      &:hover, &:focus {
        text-decoration: underline;
      }
    }
  }
`;

export default Contact;
