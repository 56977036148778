import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

const GlobalStyle = createGlobalStyle`
  ${normalize}

  :root {
    --logo-blue:  #3079B7;
    --logo-red:   #ED1B30;
    --logo-white: #FFFFFF;
  }

  html                   { box-sizing: border-box; }
  *, *::before, *::after { box-sizing: inherit; }

  html {
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: bottom;
    background-attachment: fixed;
    background-size: cover;
    background-color: black;
  }
  @media (min-width: 1441px), (min-height: 961px) {
    html { background-image: url(images/Accueil_1920.jpg); }
  }
  @media (max-width: 1440px) and (max-height: 960px) {
    html { background-image: url(images/Accueil_1440.jpg); }
  }
  @media (max-width: 1024px) and (max-height: 683px) {
    html { background-image: url(images/Accueil_1024.jpg); }
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  p {
    margin: 0 0 1.5em;
    line-height: 1.35em;
  }
  p:last-of-type {
    margin: 0;
  }
`;

export default GlobalStyle;
